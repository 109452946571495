<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")

    .col-lg-12
      .card
        .card-body
          .row 
            .col-lg-12
              p.font-weight-normal 單位中心
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-checkbox-group#checkbox-group-1(
                    :checked="searchData.type"
                    @input="changeOption($event)"
                    name="option_checkbox"
                    )
                    b-form-checkbox(
                      v-for="_option in options" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-12
              p.font-weight-normal 新聞分類
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-checkbox-group#checkbox-group-1(
                    :checked="searchData.type"
                    @input="changeOption($event)"
                    name="option_checkbox"
                    )
                    b-form-checkbox(
                      v-for="_option in options" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-4
              .form-group.mb-3
                label 上稿人員
                br
                multiselect(
                  v-model="currentEditer",
                  :options="editer",
                  placeholder="請選擇人員",
                  label="name",
                  track-by="id",
                  @search-change="searchEditerList",
                  select-label=""
                )
          b-button.width-md(variant="primary") 查詢

    
    
    .col-lg-12
      .card-body.py-2
        p 符合條件資料 共 123筆
        ul.list-unstyled 
          li 單位中心：即時中心 / 要聞中心 / 即時中心
          li 新聞分類：全選  
          li 平均單篇PV 1,XXX
          li 稿件來源：自製稿

    .col-lg-12
      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 影音節目數據列表
            b-button.width-md(variant="primary" to="" )
              span 匯出報表  

          //- Table
          .table-responsive.mb-0
            b-table(
              responsive, 
              ref="dataTable",
              :items="getData", 
              :fields="fields", 
              :per-page="perPage", 
              :current-page="currentPage", 
            )

              //- 排名
              template(v-slot:cell(index)="row") {{ row.index + 1 }}

              //- 影音節目標題
              template(v-slot:cell(title)="row") {{ row.item.title }}

              //- 播放數
              template(v-slot:cell(views)="row") {{ $root.common.formatNumber(row.item.views, 0) }}

              //- 分類
              template(v-slot:cell(type)="row") {{ row.item.type }}

              //- 稿號
              template(v-slot:cell(code)="row") {{ row.item.code }}

              //- 出稿日期
              template(v-slot:cell(submission-date)="row") {{ row.item.date }}

              //- 上稿人員
              template(v-slot:cell(editer)="row") {{ row.item.editer }}

              //- 單位
              template(v-slot:cell(unit)="row") {{ row.item.unit }}

          .row.mb-md-2
            .col-12
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
  
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import TimePeriodTab from "./components/TimePeriodTab";

const mockData = [
  { 
    title: '「綠營遛鳥俠」性騷手法曝光！徐巧芯：最少50至60人受害',
    views: 12000,
    type: '政治',
    source: '自製稿',
    code: '34566',
    date: '2022/9/9 18:25',
    editer: '王大明',
    unit: '即時中心',
  }
]

/**
 * Starter component
 */
export default {
  name: 'NewsAnalysis',
  data () {
    return {
      title: "影音節目數據查詢 / 數據分析",
      items: [{
        text: "數據分析",
        href: "/dashboard",
      },
      {
        text: "影音節目數據查詢",
        active: true,
      }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      fields: [
        { key: 'index', label: "排名", class: 'width-auto center' },
        { key: 'title', label: "新聞標題", class: 'width-auto center' },
        { key: "views", label: "播放數", class: 'width-auto center' },
        { key: "type", label: "分類", class: 'width-auto center' },
        { key: "code", label: "稿號", class: 'width-auto center' },
        { key: "date", label: "出稿日期", class: 'width-auto center' },
        { key: "editer", label: "上稿人員", class: 'width-auto center' },
        { key: "unit", label: "單位", class: 'width-auto center' }
      ],
      statusTab: 0,
      options: [
        { id: 0, name: "全部"},
        { id: 1, name: " 未處理"},
        { id: 2, name: "處理中"},
        { id: 3, name: "已結案"}
      ],
      searchData: {
        unit: [],
        type: [],
        source: [], 
        editer: ''
      },
      editer: [],
      currentEditer: false,
    };
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      this.statusTab = _status
      this.currentPage = 1
      this.reloadData()
    },
    reloadData () {
      this.$refs.dataTable.refresh()
    },
    // 獲得推播項目清單
    getData (_table, _callback) {
      let vm = this
      _callback(mockData)
      // this.$root.apis.getData({
      //   status_type: this.statusTab,
      //   page: this.currentPage
      // }, function (_response) {
      //   let response = _response.body.data
      //   vm.currentPage = response.page
      //   vm.totalRows = response.total
      //   vm.perPage = response.per_page
      //   _callback(response.items)
      // })
    },
    searchEditerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.editer = _response.body.data.items
      })
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    TimePeriodTab
  }
};
</script>
  
<style lang="sass" scoped>
.card-header
  background: #ffffff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
</style>
